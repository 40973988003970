jQuery(document).ready(function ($) {
	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});

	$('a.level').each(function (i, o) {
		var c = $(o).html().replace('kukac', String.fromCharCode(64));
		c = c.replace(RegExp(/pont/g), String.fromCharCode(46));
		$(o).attr('href', 'mai' + 'lt' + 'o:' + c).html(c);
	});

	// ----- carousel -----
	if ($('.carousel-indicators li.active').length == 0) {
		$('.carousel-indicators li:first').addClass('active');
	}
	if ($('.carousel-inner .item.active').length == 0) {
		$('.carousel-inner .item:first').addClass('active');
	}

	$('.dropdown .active').parents('.dropdown').addClass('active');

	$('.map').each(function () {

		var $this = $(this),
			loc = new google.maps.LatLng($this.data('lat'), $this.data('lng')),
			map = new google.maps.Map(this, {
				zoom: 15,
				scrollwheel: false,
				center: loc,
				mapTypeId: google.maps.MapTypeId.ROADMAP
			}),
			marker = new google.maps.Marker({
				position: loc,
				map: map,
			});
	});

	$('input[type="checkbox"].teruletek').on('change', function () {
		var $this = $(this),
			checked = $this.is(':checked'),
			$target = $($this.data('target'));
		if (checked) {
			$target.collapse('show').find('input[type="checkbox"]').removeAttr('disabled').removeProp('disabled');
		} else {
			$target.collapse('hide').find('input[type="checkbox"]').attr('disabled', 'disabled').prop('disabled', true);
		}
	});

	/*$('.kereses-lenyilo').on('click', function () {
	 if ($('#kereses-form').css('display') == 'none') {
	 $("#kereses-form").slideDown("fast");
	 }
	 else {
	 $("#kereses-form").slideUp("fast");
	 }
	 });*/

	$('.kereses-lenyilo').parent('div').on('click', function () {
		if ($('#kereses-form').css('display') == 'none') {
			$("#kereses-form").slideDown("fast");
		}
		else {
			$("#kereses-form").slideUp("fast");
		}
	});

	$('.search-close').on('click', function () {
		$("#kereses-form").slideUp("fast");
	});

	if ($('.tartalom-sav').height() > $('.oldalsav').height()) {
		$('.tartalom-sav').css('border-right', '1px solid #a3a3a3');
	}
	else {
		$('.oldalsav').css('border-left', '1px solid #a3a3a3');
	}

	$('.szoveg table').each(function () {
		$(this).wrap("<div class='table-responsive'></div>");
	});

});
function onSubmitAjanlat(token) {
	document.getElementById("ajanlatForm").submit();
}

function onSubmitHirlevel(token) {
	var form = $("#hirlevelForm");
	console.log(form);
	$(form).ajaxSubmit({
		method: 'POST',
		success: function (resp) {
			cbs.callbackHandler(form.data('callback'), resp, form);
			window.location = 'https://ergonom.hu/hirlevel/koszonjuk';
			//window.location='http://ergonom.fwl.hu/hirlevel/koszonjuk';

		}
	});
}

function onSubmitKepzes(token) {
	document.getElementById("kepzesForm").submit();
}

function onSubmitApplication(token) {
	var applicationForm = document.getElementById("applicationForm");

	$(applicationForm).find('.applicant').filter(function () {
		return $(this).css('display') === 'none';
	}
	).remove();

	applicationForm.submit();
}

// ----- ajax-os delegálások -----
$(document).on('submit', 'form.ajax', function (e) {
	e.preventDefault();
	var $this = $(this);
	$(this).ajaxSubmit({
		method: $this.data('method') || $this.attr('method') || 'POST',
		success: function (resp) {
			cbs.callbackHandler($this.data('callback'), resp, $this);
		}
	});
});

$(document).on('click', 'div.menu-doboz', function (e) {
	e.preventDefault();
	var link = $(this).children('.menu-link');
	var doboz = $(this);

	if (link.data('toggle') != 'dropdown') {
		window.location.href = link.attr('href');
	}
	else {
		if (doboz.hasClass('nyitott')) {
			doboz.removeClass('open');
			doboz.removeClass('nyitott');
		} else {
			doboz.addClass('open');
			doboz.addClass('nyitott');
		}
	}
});

$(document).on('click', 'div.menu-doboz > .menu-link', function () {

	var doboz = $(this).parent('.menu-doboz');
	if (doboz.hasClass('nyitott')) {
		doboz.removeClass('nyitott');
	} else {
		doboz.addClass('nyitott');
	}
});
$(document).on('click', 'div.menu-doboz  .almenu-link', function (e) {
	e.preventDefault();
	window.location.href = $(this).attr('href');
});

$(document).on('change blur', 'form.auto :input', function (e) {
	e.preventDefault();
	$(this).parents('form').submit();
});

$(document).on('click', 'a.ajax', function (e) {
	e.preventDefault();
	var $this = $(this);
	$.ajax({
		url: $this.attr('href'),
		method: $this.data('method') || 'GET',
		data: $this.data(),
		success: function (resp) {
			cbs.callbackHandler($this.data('callback'), resp, $this);
		}
	});
});

var cbs = {
	callbackHandler: function (cb, resp, $elem) {
		$.each(cb.split(',') || [], function (i, c) {
			if (c && $.isFunction(cbs[c])) {
				cbs[c](resp, $elem);
			}
		});
	},
	morepages: function (resp, $elem) {
		$($elem.data('target')).append(resp);
		$elem.remove();
	},
	hirlevel: function (resp, $elem) {
		$elem.find('.has-error').each(function () {
			$(this).removeClass('has-error').find('span.help-block').remove();
		});

		if (resp.status == 'success') {
			$elem.html(resp.uzenet);
		} else if (resp.status == 'error') {
			$.each(resp.errors, function (key, value) {
				value = $.isArray(value) ? value : [value];
				$elem.find('[name="' + key + '"]').parents('.form-group').addClass('has-error').append('<span class="help-block">' + value.join('<br>') + '</span>');
			});
		}
	},
};

var recaptcha = function () {
	$('.g-recaptcha').each(function () {
		var $this = $(this);
		grecaptcha.render($this[0], $this.data());
	})
};

// ----- application form -----
jQuery(document).ready(function ($) {
	const applicantList = $('#applicants-list');
	const maxApplicantWarningText = '<div class="alert alert-info text-justify max-application-count-text" role="alert"><strong>FIGYELEM! Tájékoztatjuk, hogy jelen online jelentkezési űrlap maximum 10 fő képzésen résztvevő személy regisztrálása estén alkalmazható. Kérjük, amennyiben képzésünkre 10 főnél több képzésen résztvevő személyt szeretne regisztrálni írjon e-mailt az <a href="mailto:ertekesites@ergonom.hu">ertekesites@ergonom.hu</a> címre és kollégáink keresni fogják. Megértését köszönjük!</strong></div>';

	let visibleCount = 1; // from application.blade.php
	if (applicantsCount && applicantsCount > 1) {
		visibleCount = applicantsCount;
	}
	for (let i = 1; i < visibleCount; i++) {
		applicantList.children('.applicant').filter(function () {
			return $(this).css('display') === 'none';
		}
		).first().show();
	}

	$('#addApplicant').on('click', function () {
		applicantList.children('.applicant').filter(function () {
			return $(this).css('display') === 'none';
		}
		).first().show();

		visibleCount = applicantList.children('.applicant').filter(function () {
			return $(this).css('display') !== 'none';
		}
		).length;

		if (visibleCount > 1) {
			$('#removeApplicant').show();
		}

		if (visibleCount === 10) {
			$('#addApplicant').hide();
			$('#addApplicant').after(maxApplicantWarningText);
		}
	});

	$('#removeApplicant').on('click', function () {
		applicantList.children('.applicant').filter(function () {
			return $(this).css('display') !== 'none';
		}
		).last().hide();

		visibleCount = applicantList.children('.applicant').filter(function () {
			return $(this).css('display') !== 'none';
		}
		).length;

		if (visibleCount === 1) {
			$('#removeApplicant').hide();
		}

		if (visibleCount < 10) {
			$('#addApplicant').show();
			$('#addApplicant').next('.max-application-count-text').remove();
		}
	});

	$('#billing_method').on('change', function () {
		var value = $(this).val();
		$('#billing_post_address').parent().toggle(value === '1');
		$('#billing_post_address').attr('required', value === '1');

		$('#billing_email_address').parent().toggle(value === '2');
		$('#billing_email_address').attr('required', value === '2');

		if (value === '2') {
			$('#billing_method').after('<div style="margin-top: 5px;"><strong class="text-primary small">Felhívjuk figyelmét, hogy a pdf formátumban megküldött számla, nem egyenlő az elektronikus számlával!</strong></div>');
		} else {
			$('#billing_method').next('div').remove();
		}
	});

	// tűzvédelmi kat. required off, csak backend validáció van
	/* $('.form-group input[type="checkbox"]').on('change', function () {
		var $this = $(this);
		var formGroup = $this.parents('.form-group');
		var checkboxes = formGroup.find('input[type="checkbox"]');
		var checked = checkboxes.filter(':checked');

		checkboxes.prop('required', false);
		checked.prop('required', true);

		if (checked.length === 0) {
			checkboxes.prop('required', true);
		}
	}); */

	$('#employer_bank_account').on('input', function () {
		var input = $(this).val().replace(/\D/g, '');  // Remove any non-digit characters
		var formattedInput = '';

		if (input.length > 8) {
			formattedInput = input.substring(0, 8) + '-';
		} else {
			formattedInput = input;
		}

		if (input.length > 16) {
			formattedInput += input.substring(8, 16) + '-';
			formattedInput += input.substring(16, 24);
		} else if (input.length > 8) {
			formattedInput += input.substring(8, 16);
		}

		$(this).val(formattedInput);
	});

	$('#employer_tax_number').on('input', function () {
		var input = $(this).val().replace(/\D/g, '');  // Remove any non-digit characters
		var formattedInput = '';

		if (input.length > 8) {
			formattedInput = input.substring(0, 8) + '-';
		} else {
			formattedInput = input;
		}

		if (input.length > 9) {
			formattedInput += input.substring(8, 9) + '-';
			formattedInput += input.substring(9, 11);
		} else if (input.length > 8) {
			formattedInput += input.substring(8, 9);
		}

		$(this).val(formattedInput);
	});
});